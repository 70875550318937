import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import "./ItemAccordion.scss"
import { formatDate, formatDateWithWeekDay, formatTime } from "../commonFunctions"
import deleteIcon from "../../images/history/delete_icon.svg"
import editIcon from "../../images/history/edit_icon.svg"
import ratingIcon from "../../images/history/rating_icon.svg"
import { ReactComponent as LocationIcon } from "../../images/history/location_icon.svg"
import { ReactComponent as RatingStarIcon } from "../../images/history/rating_star_icon.svg"
import { ReactComponent as RefundIcon } from "../../images/history/refund_icon.svg"
import { ReactComponent as DeliveredIcon } from "../../images/history/delivered_icon.svg"
import { ReactComponent as CancelledIcon } from "../../images/history/cancelled_icon.svg"
import { ReactComponent as TimerIcon } from "../../images/history/clock_icon.svg"
import { ReactComponent as RedAlert } from "../../images/history/red_alert_circle_icon.svg"
import { ReactComponent as AlertTriangle } from "../../images/history/alert_triangle_outline.svg"
import { Trans } from "react-i18next"
import moment from "moment"

function ItemAccordion({
  item,
  handleOpenEditDialog,
  setRatingDialogData,
  setDeleteDialogData
}) {
  const {t} = useTranslation("main")
  const { innerWidth: width } = window

  const [isSelected, setIsSelected] = useState(false)
  const [setHeight, setHeightState] = useState("0px")

  const content = useRef(null)

  function toggleAccordion() {
    setIsSelected(!isSelected)
    if (!isSelected) {
      setHeightState(`${content.current.scrollHeight}px`)
    } else {
      setHeightState(`0px`)
    }
  }
  const isDelivered = moment(item.MealDate.substring(0, 11) + item.DeliveryMinTime).isBefore()
  const isCancelled = item.Status === 2
  const isRefunded = !!item.RefundDate

  function renderStatusBlock() {
    if (isRefunded) {
      return (
        <div className="status_block status_block--refunded">
          <RefundIcon className="status_block__icon"/> {t("history.details.status.refunded")}
        </div>
      )
    }
    if (isCancelled) {
        return (
            <div className="status_block status_block--cancelled">
              <CancelledIcon className="status_block__icon"/> {t("history.details.status.cancelled")}
            </div>
        )
    }
    if (isDelivered) {
      return (
        <div className="status_block status_block--delivered">
          <DeliveredIcon className="status_block__icon"/> {t("history.details.status.delivered")}
        </div>
      )
    }
    return (
      <div className="status_block">
        <TimerIcon className="status_block__icon"/> {t("history.details.status.in_progress")}
      </div>
    )
  }

  function renderRemarks() {
    const closeDate = formatDate(item.EditClose)
    const closeTime = formatTime(item.EditClose)
    return moment().diff(item.MealDate, "days") > 0 ? (
      ""
    ) : moment(item.CancelClose).isBefore()
        && moment(item.EditClose).isBefore() ? (
      <span className="order-info__info--dark">
        <AlertTriangle height={16} width={16} className="info-icon" />
        {t("history.info.dish_is_ready_no_edit")}
      </span>
    ) : moment(item.CancelClose).isBefore() ? (
      <span className="order-info__info--dark">
        <AlertTriangle height={16} width={16} className="info-icon" />
        {t("history.info.dish_no_cancel")}
      </span>
    ) : (
      <span className="order-info__info--red">
        <RedAlert height={16} width={16} className="info-icon" />
        <Trans t={t} i18nKey="history.info.change_until">
          Can be changed up to {{ closeDate }} at {{ closeTime }}
        </Trans>
      </span>
    )
  }

  function renderButtons() {
    return (
      <div className="order-info__button-row">
        {!item.Rating ? (
          <>
            {moment(item.CancelClose).isAfter() && (
              <button
                className="order-info__button"
                onClick={(event) => {
                  event.stopPropagation()
                  setDeleteDialogData({
                    show: true,
                    OrderId: item.OrderID,
                    ItemID: item.ItemID,
                    ComboID: item.ComboID
                  })
                }}
              >
                <img src={deleteIcon} alt="delete" />
                <div className="img-partner">
                  {t("history.info.delete_btn")}
                </div>
              </button>
            )}
            {moment(item.EditClose).isAfter() && (
              <button
                className="order-info__button"
                onClick={(event) => {
                  event.stopPropagation()
                  handleOpenEditDialog(item)
                }}
              >
                <img src={editIcon} alt="edit" />
                <div className="img-partner">
                  {t("history.info.edit_btn")}
                </div>
              </button>
            )}
            {isDelivered && (
                <button
                  className="order-info__button"
                  onClick={(event) => {
                    event.stopPropagation()
                    setRatingDialogData({
                      show: true,
                      id: item.OrderItemID
                    })
                  }}
                >
                  <img
                    src={ratingIcon}
                    alt="rating"
                    style={{ width: 16 }}
                  />
                  <div className="img-partner">
                    {t("history.info.rating_btn")}
                  </div>
                </button>
              )}
          </>
        ) : (
          <div className="rating-display">
            <RatingStarIcon className="rating-star fill-red" />
            <div className="rating-display__number">{item.Rating}</div>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="item_accordion__block" key={item.Id}>
      <div
        className={`accordion ${isSelected ? "active" : ""}`}
      >
        <div className="accordion__title" onClick={toggleAccordion}>
          <div className="order-info__title">
            {renderStatusBlock()}
            <div className="order-info__name">
              {item.ItemName}
            </div>
          </div>
          <div>
            <span className='ordered-item-block-price_info-title'>
             {item.SubsidyAmount === 0 && <>{t("order.total")}: {item.TotalCost} <br /></>}
             {item.CostumerCreditAmount !== 0 && <>{t("main.confirmation.customerCredit")}: {item.CostumerCreditAmount} <br /></>}
             {item.CreditCardAmount !== 0 && <>{t("main.confirmation.creditCard")}: {item.CreditCardAmount} <br /></>}
             {item.BudgetAmount !== 0 && <>{t("main.confirmation.budget")}: {item.BudgetAmount} <br /></>}
             {item.WalletAmount !== 0 && <>{t("main.confirmation.wallet")}: {item.WalletAmount} <br /></>}
            </span>
          </div>
          <div>
            {
                item.MealDate && (
                    <>
                      <span className='ordered-item-block-delivery-time-title'>
                          {t('history.details.order_for_date')}&nbsp;
                      </span>
                      <span>{formatDateWithWeekDay(item.MealDate, true, ".")}</span>
                    </>
                )
            }
          </div>
          {width >= 1024 && !isCancelled && !isRefunded && (
            <div className="order-info__actions">
              <div className="order-info__remarks">
                {renderRemarks()}
              </div>
              {renderButtons()}
            </div>
          )}
        </div>

        <div
          ref={content}
          className="accordion__content"
          style={{ maxHeight: `${setHeight}` }}
        >
          <div className="accordion__text">
            <div className="order-info" key={item.OrderID}>
              {/* <div className="order-info__date">
                {formatDate(item.MealDate)}
                <div
                  className="details"
                  onClick={() => setDetailsData({ show: true, data: item })}
                >
                  {t("history.details.btn")}
                </div>
              </div> */}
              <div className="order-info__info">
                {t("history.details.order_date")}: <span className="order-info__order-day">{formatDateWithWeekDay(item.OrderDate, true, ".")}</span>
              </div>
              <div className="order-info__info">
                <LocationIcon height={16} width={16} className="info-icon" />
                {item.SupplierName}
              </div>
              <div className="order-info__info">
                <span className="info-icon">#</span>
                {t("history.info.order_number")} {item.OrderNumber}
              </div>
              <div className="divider" />
              <div className="order-info__info">
                {t("history.details.order_for_date")}: <span className="order-info__order-day">{formatDateWithWeekDay(item.MealDate, true, ".")}</span>
              </div>
              <div>
              <div className="order-info__info">
                {t("history.details.delivery_location")}: <span className="order-info__order-day">{item.LocationName}</span>
              </div>
              </div>
              {width < 1024 && !isCancelled && !isRefunded && (
                <>
                  <div className="divider" />
                  <div className="order-info__info">{renderRemarks()}</div>
                  {renderButtons()}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ItemAccordion
